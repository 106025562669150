import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
  InputLeftAddon,
} from "@chakra-ui/react"
import { RegisterOptions, useFormContext } from "react-hook-form"
import { KnownIcon } from "../../widgets/KnownIcon"

interface PhoneFieldProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function PhoneField({
  label,
  name,
  registerOptions,
  helper,
}: PhoneFieldProps) {
  const { register, setValue, formState, trigger } = useFormContext()

  const transformToCanadianFormat = (value: string) => {
    const numericValue = value.replace(/\D/g, "")

    if (numericValue?.length > 3) {
      // Formatting to Canadian pattern ###-###-####
      const formattedValue = `${numericValue.slice(0, 3)}-${numericValue.slice(
        3,
        6,
      )}-${numericValue.slice(6, 10)}`
      // Set the formatted value in the field
      setValue(name, formattedValue)
      trigger(name)
    }
  }

  const validatePhone = (value: string | undefined) => {
    if (value) {
      const isValidPhone = /^\d{3}-\d{3}-\d{4}$/.test(value)
      if (isValidPhone) {
        return undefined // Valid phone number
      }
      return "Invalid phone number format. Please use ###-###-####"
    }
  }

  // Default registration options
  const defaultRegisterOptions = {
    validate: {
      validPhone: validatePhone,
    },
  }

  // Merge provided registerOptions with default options
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  return (
    <FormControl isInvalid={!!formState.errors?.[name]}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <InputLeftAddon>
          <KnownIcon name="phone" />
        </InputLeftAddon>
        <Input
          inputMode="numeric"
          {...register(name, mergedRegisterOptions)}
          placeholder="###-###-####"
          onBlur={(e) => transformToCanadianFormat(e.target.value)}
        />
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
