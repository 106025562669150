import { useEffect, useState } from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { Box, Button, Flex, Icon, Spinner, Text } from '@chakra-ui/react';
import { KnownIcon } from '../../widgets/KnownIcon';
import { useRetriveAttachmentsQuery, useRemoveAttachmentMutation } from '../../../features/aws/awsApi';
//filepond
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { ImageWithSkeleton } from './ImageWithSkeleton';
import { useCustomToast, useNetworkCheck, useUploadFileToS3 } from '../../../app/hooks';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export interface Attachment {
    id?: number | null;
    presigned_url: string | null;
    file_key?: string | null;
}

interface AttachmentProps {
    draftId?: string | null
    onSave?(data: any): void
    onSaveToDraft?(data: any): void
}
export const AttachmentField = ({ draftId, onSave, onSaveToDraft }: AttachmentProps) => {
    const { isOnline } = useNetworkCheck()
    const { upload } = useUploadFileToS3()
    const { showToast } = useCustomToast()
    const [files, setFiles] = useState<File[]>([]);
    const [errorFiles, setErrorFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [removeAttachment] = useRemoveAttachmentMutation();
    const { data: attachments, isLoading } = useRetriveAttachmentsQuery(draftId, { skip: !draftId });

    const MAX_FILE_SIZE = import.meta.env.VITE_MAX_FILE_SIZE;
    const ACCEPTED_FILE_TYPES = import.meta.env.VITE_FILEPOND_ACCEPTED_FILE_TYPES.split(',');
    const FILE_LIMIT = import.meta.env?.VITE_FILEPOND_LIMIT;

    useEffect(() => {

        if (attachments && attachments?.length > 0) {
            const file_keys = attachments.map((item: Attachment) => (item.file_key))
            if (onSave) {
                onSave(file_keys)
            }
            else if (onSaveToDraft) {
                onSaveToDraft(file_keys)
            }
        }
    }, [attachments])

    const handleUpload = async () => {
        if (!files.length) return;
        setIsUploading(true)
        try {
            draftId && await upload({ files, draftId, folderType: "attachments" })
            showToast({
                status: 'success',
                description: 'Attachments uploaded successfully',
            });
            setFiles([]);
        } catch (error) {
            console.error('Error uploading files:', error);
            showToast({
                status: 'error',
                description: 'Something went wrong , try after a while',
            });

        } finally {
            setIsUploading(false)
        }
    };

    const handleRemove = async (fileKey: string) => {
        if (draftId) {
            await removeAttachment({ draft_id: draftId, file_key: fileKey })
        };
    };

    const handleFileError = (error: any, file: any) => {
        if (error) {
            setErrorFiles((prev) => [...prev, file?.file])
        }
    }

    const handleRemoveFile = (error: any, file: any) => {
        setErrorFiles((prev) => prev.filter((f) => f !== file.file));
    }

    return (
        <>
            <Flex gap={4} my={4} width="full" wrap="wrap">
                {!isLoading && attachments && attachments?.length > 0 && attachments.map((attachment: Attachment) => (
                    <Box key={attachment.file_key} position="relative">
                        <ImageWithSkeleton
                            key={attachment.file_key}
                            attachment={attachment}
                            handleRemove={handleRemove}
                            showRemoveIcon={true}
                        />
                    </Box>))}
            </Flex>
            {(attachments?.length || 0) < FILE_LIMIT && (
                <>
                    <Box w="full" borderWidth="3px" borderRadius="md" p={4} borderColor="#3182CE" borderStyle="dashed" position="relative">
                        <FilePond
                            files={files}
                            allowMultiple
                            maxFileSize={MAX_FILE_SIZE}
                            allowFileSizeValidation
                            allowFileTypeValidation
                            labelMaxFileSize={`Maximum file size is ${MAX_FILE_SIZE}`}
                            acceptedFileTypes={[...ACCEPTED_FILE_TYPES]}
                            maxFiles={FILE_LIMIT - (attachments?.length || 0)}
                            onupdatefiles={(fileItems) => setFiles(fileItems.map((fileItem) => fileItem.file as File))}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            onerror={handleFileError}
                            onremovefile={handleRemoveFile}
                        />
                        {files.length > 0 && !errorFiles.length && (
                            <Button
                                size="sm"
                                variant="solid"
                                colorScheme="blue"
                                onClick={handleUpload}
                                isLoading={isUploading}
                                leftIcon={<Icon as={KnownIcon} name="upload" size={4} />}
                                isDisabled={!isOnline}
                            >
                                Upload
                            </Button>
                        )}
                        {isUploading && (
                            <Box
                                top="0"
                                left="0"
                                width="100%"
                                height="100%"
                                display="flex"
                                borderRadius="md"
                                alignItems="center"
                                position="absolute"
                                justifyContent="center"
                                backgroundColor="rgba(255, 255, 255, 0.8)"
                            >
                                <Spinner size="xl" color="blue.500" />
                                <Text color="blue.500" fontSize="sm" mx={2}>Uploading...</Text>
                            </Box>
                        )}
                    </Box>
                    <Text mt={2} fontSize="small" color="#A0AEC0">
                        {`You can add maximum up to ${FILE_LIMIT - (attachments?.length || 0)} files, and each file size should be less than ${MAX_FILE_SIZE}`} <br />
                        supported file types are jpg, jpeg, png and pdf.
                    </Text>
                </>)
            }
        </>
    );
};