import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { TapTile } from "../../components/TapTile"
import { getRole } from "../../app/utils"
import { Button, Wrap, Box, useMediaQuery, Flex } from "@chakra-ui/react"
import { HomeView } from "../../layout/views/HomeView"
import { selectDraftHasContent, Draft } from "../../features/draft/draftSlice"
import { LatestTickets } from "../../features/tickets/LatestTickets"
import { selectSession } from "../../features/session/sessionSlice"
import { selectCustomerById } from "../../features/customers/customersSlice"
import { useListCustomersQuery } from "../../features/customers/customersApi"
import { useListTerminalsQuery } from "../../features/terminals/terminalsApi"
import { selectTerminalById } from "../../features/terminals/terminalsSlice"
import { useListEquipmentsQuery } from "../../features/equipments/equipmentsApi"
import { useListProductsQuery } from "../../features/products/productsApi"
import { useListServicesQuery } from "../../features/services/servicesApi"
import { useListLocationsQuery } from "../../features/terminals/locationsApi"
import { useNetworkCheck } from "../../app/hooks"
import { useListActivitiesQuery } from "../../features/activities/activitiesApi"

interface type {
  data: Draft
  index: number
}
function CurrentDraftTapTile({ data, index }: type) {
  const draftHasContent = useAppSelector(selectDraftHasContent(index))
  const fallbackCustomerName = useAppSelector(
    (state) => selectCustomerById(state, data?.basic?.customer || 0)?.name,
  )
  const customerName = data?.basic?.customer
    ? typeof data?.basic?.customer == "number"
      ? `Customer "${fallbackCustomerName}"`
      : `New Customer "${data?.basic?.customer}"`
    : "No Customer Selected"

  const fallbackTerminalName = useAppSelector(
    (state) => selectTerminalById(state, data?.consignee?.terminal || 0)?.name,
  )
  const terminalName = data?.consignee?.terminal
    ? typeof data?.consignee?.terminal == "number"
      ? `Terminal "${fallbackTerminalName}"`
      : `New Terminal "${data?.consignee.terminal}"`
    : "No Terminal Selected"

  return (
    draftHasContent && (
      <TapTile
        title={customerName}
        subtitle={terminalName}
        draftNumber={data?.number ?? null}
        signature="Current Ticket Draft"
        href={`/user/draft/${++index}`}
      />
    )
  )
}

export function DriverHomePage() {
  const navigate = useNavigate()
  const session = useAppSelector(selectSession)
  const role = session?.profile.role  // get role of current user
  const { isOnline } = useNetworkCheck()
  useListCustomersQuery()
  useListTerminalsQuery()
  useListEquipmentsQuery()
  useListProductsQuery()
  useListServicesQuery()
  useListLocationsQuery()
  useListActivitiesQuery()
  // const { isLoading, isError } = useListTicketsQuery();
  const draftsArray = useAppSelector((state) => state.drafts.drafts)
  const [isSmallScreen] = useMediaQuery('(max-width: 620px)');

  useEffect(() => {
    if (role && role === "customer") {
      navigate("/user/tickets");
    }
  }, [role, navigate]);
  return (
    <HomeView title={role && getRole(role)}>
      {!isOnline && (
        <Box w="full">
          <p>
            You are currently offline, but you can still modify your drafts.
          </p>
        </Box>
      )}
      <Wrap w="full">
        <TapTile
          title="Time Tracking"
          subtitle="Add and view your hours here"
          href="/user/tracking"
        />
        {draftsArray &&
          draftsArray.map((draft, index) => {
            return (
              <CurrentDraftTapTile data={draft} index={index} key={index} />
            )
          })}
      </Wrap>
      <Box w="full" p={5}>
        <LatestTickets limit={5} />
      </Box>
      <Flex w="full" flexDirection={isSmallScreen ? "column" : "row"}
        gap={isSmallScreen ? 2 : 3}
        justifyContent={isSmallScreen ? "center" : "flex-end"}
        alignItems={isSmallScreen ? "center" : "flex-end"}
      >
        {
          role === "driver-lead" &&
          <Button size={["xs", "sm", "md"]}
            w={isSmallScreen ? "45%" : "auto"}
            p={4}
            colorScheme="blue" onClick={() => navigate("/user/approve-time-entries")}
            isDisabled={isOnline ? false : true}
          >
            Approve Time
          </Button>
        }
        <Button size={["xs", "sm", "md"]}
          w={isSmallScreen ? "45%" : "auto"}
          p={4}
          colorScheme="blue" onClick={() => navigate("/user/tickets")}
          isDisabled={isOnline ? false : true}
        >
          View Tickets
        </Button>
        <Button
          colorScheme="blue"
          w={isSmallScreen ? "45%" : "auto"}
          size={["xs", "sm", "md"]}
          p={4}
          onClick={() =>
            draftsArray.length !== 0
              ? navigate(`/user/draft/${draftsArray.length + 1}`)
              : navigate(`/user/draft`)
          }
        >
          Create New Ticket
        </Button>
      </Flex>
    </HomeView>
  )
}
