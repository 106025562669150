import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Text, VStack } from '@chakra-ui/react'
import { FocusView } from '../../layout/views/FocusView'
import { Controller, useForm } from 'react-hook-form'
import { MessageBox } from '../../components/MessageBox'
import { useMessageBox } from '../../app/hooks'
import { useForgotPasswordEmailSendMutation } from '../../features/profile/profileApi'
import { Link } from 'react-router-dom'

type Inputs = {
    email: string
}

export function ForgotPasswordPage() {
    const { showAlert, alertStatus, alertMessage, errorDetail, setAlert } = useMessageBox();
    const [forgotPasswordEmailSend, { isLoading, isSuccess: isMailSent }] = useForgotPasswordEmailSendMutation()

    const defaultValues = {
        email: ''
    }

    const {
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<Inputs>({ defaultValues })

    const onSubmit = async (data: Inputs) => {
        try {
            await forgotPasswordEmailSend(data).unwrap()
            setAlert("success", "If the email you provided is associated with an account, you will receive a password reset link shortly. ")
            reset()
        } catch (error) {
            console.log("failed to send email for forgot password reset :", error);
            setAlert("error", "Something went wrong , please try again later")
        }
    }

    const validateEmail = (value: string | undefined) => {
        if (value) {
            const isValiEmail = value && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
            if (isValiEmail) {
                return undefined
            } else {
                return "Invalid Email format."
            }
        }
    }

    return (
        <FocusView>
            <VStack width="full" gap={6}>
                <MessageBox
                    show={showAlert}
                    status={alertStatus}
                    message={alertMessage}
                    error={errorDetail}
                />
                {
                    !isMailSent ?
                        <>
                            <Heading as="h5" size="md">
                                Reset Password
                            </Heading>
                            <Box w="full">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <VStack align="start" width="full" gap={3}>
                                        <FormControl isInvalid={!!errors.email}>
                                            <FormLabel>E-mail</FormLabel>
                                            <Controller
                                                control={control}
                                                name="email"
                                                rules={{ required: 'This field is required', validate: validateEmail }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        onChange={(e) => field.onChange(e.target.value.trim().length > 0 ? e.target.value : '')} // Trim value on change
                                                    />
                                                )}
                                            />
                                            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                                        </FormControl>

                                        <Flex width="full" justifyContent={["center", "flex-end"]}>
                                            <Button
                                                type="submit"
                                                size={["md"]}
                                                colorScheme="blue"
                                                isLoading={isLoading}
                                                minWidth={40}
                                            >
                                                Send
                                            </Button>
                                        </Flex>

                                        <Box w="full" textAlign="center" p={4} backgroundColor="blue.50" rounded="lg">
                                            <Text>Back to
                                                <Link to="/auth/login" className="fw-bold  ms-3" style={{ color: "#3182ce", margin: "0 10px" }}><u>Log in</u></Link>
                                            </Text>
                                        </Box>
                                    </VStack>
                                </form>
                            </Box>
                        </>
                        :
                        null
                }
            </VStack>
        </FocusView>
    )
}

