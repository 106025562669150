import FieldGroupArray from "../../components/forms/FieldGroupArray"
import { InputStreak } from "../../components/forms/InputStreak"
import { InputField } from "../../components/forms/fields/InputField"
import { NumberField } from "../../components/forms/fields/NumberField"
import TankGaugeReadingField from "../../components/forms/fields/TankGaugeReadingField"
import { BooleanField } from "../../components/forms/fields/BooleanField"
import { ProductAutocomplete } from "../products/ProductAutocomplete"

interface DangerousGoodsPickUpFormProps {
  onRemove?(index: number): void
}

export function PickUpForm({ onRemove }: DangerousGoodsPickUpFormProps) {

  return (
    <>

      <InputStreak >
        <NumberField
          label="Estimated Volume"
          name="estimated_volume"
        />
      </InputStreak>
      <InputField
        label="Sequence"
        name="sequence"
      />
      <NumberField
        label="SW Percent"
        name="sw_percent"
      />
      <FieldGroupArray
        label="Tank Gauge"
        name="tank_gauge_details"
        onRemove={onRemove}
      >
        {(fieldName1, fieldName2) =>
        (
          <>
            <TankGaugeReadingField name={fieldName1} />
            <TankGaugeReadingField name={fieldName2} />
          </>
        )
        }
      </FieldGroupArray>
      <BooleanField label="Confirmed Empty" name="confirmed_empty" />
      <ProductAutocomplete
        label="Residue Last Contained"
        name="residue_last_contained"
        helper="Select the name of the product previously shipped"
      />
    </>
  )
}
