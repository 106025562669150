import { useParams, useLocation } from "react-router-dom"
import { useRetrieveTicketQuery } from "../../features/tickets/ticketsApi"
import {
  Box,
  Button,
  Divider,
  HStack,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react"
import { components } from "../../app/api/schema"
import { useAppSelector } from "../../app/hooks"
import React, { forwardRef, useEffect, useMemo, useRef } from "react"
import { EquipmentTrait } from "../../features/equipments/EquipmentTrait"
import { formatDateOnly } from "../../app/utils"
import { Image } from '@chakra-ui/react'
import logo from "/images/logo.png"
import { selectSession } from "../../features/session/sessionSlice";
import { useRetriveSignatureQuery } from "../../features/aws/awsApi"
import { Attachment } from "../../components/forms/fields/AttachmentField"

type TicketResource = components["schemas"]["Ticket"]
type ProductResource = components["schemas"]["Product"]
type ChargeResource = components["schemas"]["Charge"]
type TankGaugeRecord = components["schemas"]["Tank_Gauge_Record"]

interface TicketInfoProps {
  ticket: TicketResource
  isWithCharge?: boolean
  signatureData?: Attachment
}

function PrintHeader({ ticket }: TicketInfoProps) {
  const location = useLocation().pathname
  return (
    <HStack w="full" gap={[2, 3]}  >
      <Image src={logo} width={20} minWidth={"15%"} />
      <Text w="60%" align="center" fontSize={{ base: 12, lg: location.includes("stamp") ? 12 : 15 }} whiteSpace="nowrap">
        Box 189, Red Earth Creek, Alberta T0G 1X0
        <Text whiteSpace="nowrap">Ph: 780-649-1111 • Fax: 780-649-3897</Text>
        <Text mt={1} fontSize={[14, 16]} fontWeight={600} whiteSpace="nowrap">GST# 837365865 RT001</Text>
      </Text>
      <Text
        w="60%"
        align="center"
        fontSize={{ base: "lg", xl: location.includes("stamp") ? "lg" : "2xl" }}
        fontWeight={600}
        textTransform="uppercase"
      >
        Loon River Trucking <br /> Bill of Lading
      </Text>
      <Text
        w="20%"
        align="right"
        fontSize={["xl", "2xl"]}
        fontWeight={600}
        fontFamily="monospace"
      >
        {ticket?.number || "--"}
      </Text>
    </HStack>
  )
}

function PrintFooter({ ticket }: TicketInfoProps) {
  return (
    <VStack w="full" className="avoidSplit" borderWidth={2} p={5} mt={3}>
      <Text fontSize={[13, 14]}>
        Consignor’s Certification: I hereby declare that the contents of this
        consignment are fully and accurately described above by the proper
        shipping name, are properly classified and packaged, have dangerous
        goods safely marks properly affixed or displayed on them, and are in all
        respects in proper condition for transport according to the
        Transportation of Dangerous Goods Regulations.
      </Text>
      <HStack w="full" justifyContent="space-between" p={2} fontSize={[13, 14]}>
        <HStack >
          <Text textTransform="uppercase" fontWeight="bold">Client Name :</Text>
          <Text marginLeft={1}  > {ticket?.consignor_contact || '--'}</Text>
        </HStack>
        <HStack >
          <Text textTransform="uppercase" fontWeight="bold">Client Approved :</Text>
          <Text marginLeft={1}    > {(ticket?.status === "stamped" || ticket.digital_signed) ? "Yes" : ""}</Text>
        </HStack>
        <HStack >
          <Text textTransform="uppercase" fontWeight="bold">Telephone :</Text>
          <Text marginLeft={1}   >{ticket?.consignor_phone || '--'}</Text>
        </HStack>
      </HStack>
    </VStack>
  )
}

interface PrintFieldProps {
  label?: string
  children: any
}

function PrintField({ label, children }: PrintFieldProps) {
  return (
    <Box>
      <Text textTransform="uppercase" fontWeight={600}>
        {label && `${label}:`}
      </Text>
      <Text>{children}</Text>
    </Box>
  )
}
function StampField({ label, children }: PrintFieldProps) {
  return (
    <Box>
      <Text fontWeight={600} display={"inline"}>
        {label && `${label}:`}
      </Text>
      <Text display={"inline"}>{children}</Text>
    </Box>
  )
}


function PrintOverview({ ticket, isWithCharge, signatureData }: TicketInfoProps) {
  const session = useAppSelector(selectSession)
  const role = session?.profile?.role


  return (
    <HStack w="full" align="start" alignItems="center" gap={1} fontSize={[13, 14]}  >
      <VStack flex="1" align="start" h="full" spacing={2} >
        <SimpleGrid w="full" columns={1}  >
          <Text fontWeight={600} fontSize={20} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" >{ticket?.customer?.name || "--"}</Text>
        </SimpleGrid>
        <SimpleGrid w="full" columns={2} spacing={1} >
          <PrintField label="Driver Name">
            {ticket?.driver.phone ? `${ticket?.driver?.first_name}${' '}${ticket?.driver?.last_name}${' '}(${ticket?.driver?.phone})` : `${ticket?.driver?.first_name}${' '}${ticket?.driver?.last_name}`}
          </PrintField>
          <PrintField label="Date">
            {formatDateOnly(ticket?.reference_date) || "--"}
          </PrintField>
        </SimpleGrid>

        <SimpleGrid w="full" columns={2} spacing={1} >
          <PrintField label="Equipment Used">
            {ticket?.equipmentdetails?.map((detail, index) => (
              <React.Fragment key={index}>
                <EquipmentTrait id={detail?.equipment_id} />
                {index !== (ticket?.equipmentdetails?.length ?? 0) - 1 && " / "}
              </React.Fragment>
            )) || "--"}
          </PrintField>
          {
            role !== "driver" ?
              <PrintField label="24 hour consignor #">
                {ticket?.emergency_contact || '--'}
              </PrintField> :
              null
          }
        </SimpleGrid>

        <SimpleGrid w="full" columns={2} spacing={1} >
          <PrintField label="Received From">
            {ticket?.consignor_terminal?.name || '--'}
          </PrintField>
          <PrintField label="LSD/Well/Battery From">
            {ticket?.consignor_lsd || '--'}
          </PrintField>
        </SimpleGrid>

        <SimpleGrid w="full" columns={2} spacing={1} >
          <PrintField label="Delivered To">
            {ticket?.consignee_terminal?.name || '--'}
          </PrintField>
          <PrintField label="LSD/Well/Battery To">
            {ticket?.consignee_lsd || '--'}
          </PrintField>
        </SimpleGrid>

        <SimpleGrid w="full" columns={2} spacing={1} >

          <PrintField label="Point of Origin ">
            {ticket?.consignor_location?.name || '--'}
          </PrintField>
          <PrintField label="Destination Point">
            {ticket?.consignee_location?.name || '--'}
          </PrintField>
        </SimpleGrid>
        <SimpleGrid w="full" columns={2} spacing={1} >

          <PrintField label="Confirmed Empty">
            {ticket?.confirmed_empty ? "Yes" : "No"}
          </PrintField>
          <PrintField label="Last Contained">
            {ticket?.residue_last_contained?.name || '--'}
          </PrintField>
        </SimpleGrid>
        <SimpleGrid w="full" columns={2} spacing={1} >
          <PrintField label="Sequence">
            {ticket?.sequence || '--'}
          </PrintField>
          <PrintField label="SW Percent">
            {ticket?.sw_percent || '--'}
          </PrintField>
        </SimpleGrid>
        <SimpleGrid w="full" columns={2} spacing={1} >
          <PrintField label="Billing Email Address">
            {ticket?.billing_email || "--"}
          </PrintField>
        </SimpleGrid>
      </VStack>
      {
        (isWithCharge || role === "customer") &&
        <VStack w="300px" h="350px" borderWidth={2} borderColor="gray" p={2} fontWeight={500} justifyContent="space-between">
          <Text w="full" align="center" fontWeight={600}>
            AFE STAMP
          </Text>
          <VStack align="start" w="full" p={1} gap={8}>
            <VStack align="start" gap={2}>
              <StampField label="AEF# / Cost Class"> {ticket?.cost_class} </StampField>
              <StampField label="Minor"> {ticket?.minor_class} </StampField>
              <StampField label="Major"> {ticket?.major_class} </StampField>
              <StampField label="Other"> {ticket?.other} </StampField>
            </VStack>
            <Box w="full">
              {
                (isWithCharge || role === "customer") && signatureData &&
                < Image
                  w="full"
                  h="50px"
                  src={signatureData?.presigned_url || ""}
                  objectFit="contain"
                />
              }
              <Divider borderColor="black" />
              <Text fontWeight={600}>
                Signature
              </Text>
            </Box>
          </VStack>
        </VStack>
      }
    </HStack>
  )
}

interface TankGaugeDetailsProps {
  records: TankGaugeRecord[]
}
function PrintTankGaugeReadings({ records }: TankGaugeDetailsProps) {

  return (
    <>
      <VStack w="full" gap={0} >
        <Text fontWeight={600} w={"full"} textAlign={"center"}>TANK GUAGE READINGS</Text>
        <Table fontSize={[13, 14]} >
          <Thead>
            <Tr>
              <Th p={2} textAlign="center">TANK</Th>
              <Th p={2} textAlign="center" w={[0, 300]}>START</Th>
              <Th p={2} textAlign="center">FINISH</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              records && records?.length !== 0 ?
                records.map((record: TankGaugeRecord, index: number) => {
                  return (
                    <Tr key={index}>
                      <Td p={1} textAlign="center">{++index}</Td>
                      <Td p={1} textAlign="center">{record?.start || '--'}</Td>
                      <Td p={1} textAlign="center">{record?.finish || '--'}</Td>
                    </Tr>
                  )
                })
                :
                <Tr>
                  <Td p={1} colSpan={3} textAlign="center">No tank gauge readings.</Td>
                </Tr>
            }
          </Tbody>
        </Table>
      </VStack >
    </>
  )
}

function PrintProducts({ ticket }: TicketInfoProps) {
  const productsById = useMemo(() => {
    const productsObj: { [key: number]: ProductResource } = {}
    ticket?.products.forEach((product) => {
      productsObj[product.id] = product
    })
    return productsObj
  }, [ticket?.products])

  return (
    <VStack w="full" className="container" >
      <Text fontWeight={600} w={"full"} textAlign={"center"}>SHIPMENT</Text>

      <Table fontSize={[13, 14]} >
        <Thead>
          <Tr className="avoidSplit">
            <Th p={2} textAlign="center">Number</Th>
            <Th p={2} textAlign="center" w={[0, 300]}>Shipping Name</Th>
            <Th p={2} textAlign="center">Primary Class</Th>
            <Th p={2} textAlign="center">Subsidiary Class</Th>
            <Th p={2} textAlign="center">Packing Group</Th>
            <Th p={2} textAlign="center">Toxic by Inhalation</Th>
            <Th p={2} textAlign="center">Total Quantity</Th>
            <Th p={2} textAlign="center"># of Packages</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            ticket?.productdetails && ticket?.productdetails?.length > 0 ? (
              ticket?.productdetails?.map((detail, index) => {
                const product = productsById[detail.product_id]
                return (
                  <Tr key={index} className="avoidSplit">
                    <Td p={1} textAlign="center">{product?.un_number || '--'}</Td>
                    <Td p={1} textAlign="center">{product?.name || '--'}</Td>
                    <Td p={1} textAlign="center">{product?.primary_class || '--'}</Td>
                    <Td p={1} textAlign="center">{detail?.subsidiary_class || '--'}</Td>
                    <Td p={1} textAlign="center">{product?.packing_group || '--'}</Td>
                    <Td p={1} textAlign="center">{detail?.toxic_by_inhalation ? "Yes" : 'No'}</Td>
                    <Td p={1} textAlign="center">{detail?.quantity || '--'}</Td>
                    <Td p={1} textAlign="center">{detail?.packages || '--'}</Td>
                  </Tr>
                )
              })) :
              (
                <Tr>
                  <Td p={1} colSpan={8} textAlign="center">No entiries!!</Td>
                </Tr>
              )
          }
        </Tbody>
      </Table>
    </VStack>
  )
}

interface PrintChargesProps {
  charges: ChargeResource[]
}

function PrintCharges({ charges }: PrintChargesProps) {
  return (
    <VStack w="full" className="container" textAlign={"center"} gap={0} >
      <Text fontWeight={600} w={"full"} textAlign={"center"}>BILLING</Text>
      <Table fontSize={[13, 14]} >
        <Thead>
          <Tr>
            <Th p={1} w={300}>Description</Th>
            <Th p={1} >Quantity</Th>
            <Th p={1} >Unit</Th>
            <Th p={1} >Rate</Th>
            <Th p={1} >Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            charges.length > 0 ? (
              charges?.map((charge, index) => {
                return (
                  <Tr key={index} >
                    <Td p={1} >{charge.description || '--'}</Td>
                    <Td p={1} >{charge.quantity || '--'}</Td>
                    <Td p={1} >{charge.unit || '--'}</Td>
                    <Td p={1} >{charge.rate || '--'}</Td>
                    <Td p={1} >{charge.amount || '--'}</Td>
                  </Tr>
                )
              })
            ) : (
              <Tr>
                <Td p={1} colSpan={5} textAlign="center">No entiries!!</Td>
              </Tr>
            )
          }
        </Tbody>
      </Table>
    </VStack>
  )
}

interface PrintDescriptionProps {
  ticket: TicketResource,
  charges: ChargeResource[]
}
function PrintDescription({ ticket, charges }: PrintDescriptionProps) {

  const totalAmount = useMemo(() =>
    charges.reduce((total, { amount }) => total + Number(amount), 0)
    , [charges]);

  const description = useMemo(() => {
    const desc: string[] = [];
    if (ticket?.work_description) {
      desc.push(ticket.work_description);
    }
    ticket?.servicedetails?.forEach(({ description }) => {
      if (description) {
        desc.push(description);
      }
    });
    return desc;
  }, [ticket]);

  return (
    <HStack w="full" className="avoidSplit" h="fit-content" borderWidth={2} p={2} mt={3} justifyContent="space-between" fontSize={[13, 14]} >
      <Box p={3} maxW={"70%"}>
        <Text fontWeight={600}>DESCRIPTION OF WORK</Text>
        <Text whiteSpace="normal" wordBreak="break-word">{description.join('. ') || '--'}</Text>
      </Box>
      <Box p={3}>
        <Text fontWeight={600}>TOTAL CHARGES : </Text>
        <Text > {(totalAmount).toFixed(2)}</Text>
      </Box>
    </HStack>
  )
}

interface PrintContentProps {
  ticket: TicketResource
  charges: ChargeResource[]
  isWithCharge?: boolean,
  signatureData?: Attachment
}

export const PrintContent = forwardRef<HTMLDivElement, PrintContentProps>(
  ({ ticket, charges, isWithCharge, signatureData }: PrintContentProps, ref) => {
    return (
      <VStack w="full" spacing={0} padding={0} >
        <PrintHeader ticket={ticket} />
        <PrintOverview ticket={ticket} isWithCharge={isWithCharge} signatureData={signatureData} />
        <PrintTankGaugeReadings records={ticket?.tank_gauge_details ?? []} />
        <PrintProducts ticket={ticket} />
        {isWithCharge ? <PrintCharges charges={charges} /> : null}
        {isWithCharge ? <PrintDescription ticket={ticket} charges={charges} /> : null}
        {isWithCharge ? <PrintFooter ticket={ticket} /> : null}
      </VStack>
    )
  },
)

export function TicketDetailPrint() {
  const { ticketId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const withCharge = searchParams.get('withCharge') === "true";
  const { data: ticket, isLoading } = useRetrieveTicketQuery(Number(ticketId));
  const { data: signatureData, isLoading: isSignatureDataLoading } = useRetriveSignatureQuery(ticket?.number, { skip: !(ticket?.number && ticket.digital_signed) });

  const hasPrinted = useRef(false);
  useEffect(() => {
    if (ticket && !hasPrinted.current && !isSignatureDataLoading) {
      const printTimeout = setTimeout(() => {
        document.getElementById("printBtn")?.click();
        hasPrinted.current = true;
      }, 500);
      return () => clearTimeout(printTimeout);
    }
  }, [ticket, isSignatureDataLoading, signatureData]);

  if (ticket === undefined) return null;

  return (!(isLoading && isSignatureDataLoading) &&
    <>
      <Button display={"none"} id="printBtn" onClick={() => window.print()} />
      <PrintContent ticket={ticket} charges={ticket.chargedetails || []} isWithCharge={withCharge} signatureData={signatureData?.[0]} />
    </>
  )
}
