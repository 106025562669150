import React, { cloneElement, useState } from "react"
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Spinner,
  VStack,
  Wrap,
  WrapItem,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react"
import { Panel, PanelProps } from "../Panel"
import { FormPanelDisplayProps } from "./FormPanelDisplay"
import { FormPanelControlsProps } from "./FormPanelControls"
import { ModalForm } from "./ModalForm"
import { KnownIcon } from "../widgets/KnownIcon"
import { useNetworkCheck } from "../../app/hooks"
import { useLocation } from "react-router-dom"

interface FormCollectionPanelProps extends PanelProps {
  title: string,
  onSave?(data: any): void
  onRemove?(index: number, data?: any): void
  entries?: any[]
  isSubmitToTimeSheet?: boolean
  setSurcharge?: React.Dispatch<React.SetStateAction<boolean>>,
  isLoading?: boolean,
  children: [
    React.ReactElement<FormPanelDisplayProps>,
    React.ReactElement<FormPanelControlsProps>,
  ]
}

export function FormCollectionPanel({
  onSave,
  onRemove,
  entries = [],
  children,
  setSurcharge,
  isSubmitToTimeSheet,
  isLoading,
  ...panelProps
}: FormCollectionPanelProps) {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure()
  const { isOnline } = useNetworkCheck()
  const location = useLocation().pathname
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"
  const [display, controls] = children
  const [defaultValue, setDefaultValues] = useState(null)

  const handleSave = (data: any) => {
    onSave?.(data)
    closeModal()
  }
  const removeEntry = (index: number) => {
    onRemove?.(index)
  }
  const handleAddButton = () => {
    setSurcharge?.(false)
    setDefaultValues(null)
    openModal()
  }
  const isButtonDisabled = !isOnline && !(location.includes("user/tracking") || location.includes("user/draft"));

  return (
    <Panel {...panelProps}>
      <VStack w="full" position="relative">
        {

          isLoading ? (
            <Box
              top="0"
              left="0"
              width="100%"
              height="100%"
              display="flex"
              borderRadius="md"
              alignItems="center"
              position="absolute"
              justifyContent="center"
              backgroundColor="rgba(255, 255, 255, 0.8)"
            >
              <Spinner size="xl" color="blue.500" />
            </Box>
          ) : (
            entries?.map((entry, index) => (
              <Wrap w="full" key={index}>
                {cloneElement(display, { entry })}
                <WrapItem alignItems="flex-end" gap={2} verticalAlign="center">
                  <IconButton
                    aria-label="Remove"
                    icon={<KnownIcon name="minus" />}
                    onClick={() => removeEntry(index)}
                  />
                </WrapItem>
              </Wrap>
            )))}
      </VStack>

      {/* Button to open the dialog for adding new entries */}
      <ButtonGroup width="full" mt={3} justifyContent="end">
        <Button
          colorScheme="gray"
          size={["sm", "md"]}
          onClick={handleAddButton}
          isDisabled={isButtonDisabled}>
          Add New Entry
        </Button>
      </ButtonGroup>

      {/* Dialog for adding new entries */}
      <ModalForm
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={handleSave}
        defaultValues={defaultValue}
        isSubmitToTimeSheet={isSubmitToTimeSheet}
      >
        {cloneElement(controls)}
      </ModalForm>
    </Panel>
  )
}
