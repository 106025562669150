import { FormControl, FormErrorMessage, Input, InputGroup } from '@chakra-ui/react'
import { useController, useFormContext } from 'react-hook-form'
type TankGaugeReadigsProps = {
    name: string
}

function TankGaugeReadingField({ name }: TankGaugeReadigsProps) {

    const { control } = useFormContext()
    const { field } = useController({ name, control })
    const { formState: { errors }, register } = useFormContext()
    const pathParts = name.split('.');
    const arrayPathParts = pathParts[0].split('[');
    const arrayFieldName = arrayPathParts[0];
    const arrayIndex = arrayPathParts[1]?.slice(0, -1);
    const fieldName = pathParts[1];
    const typedErrors = errors as Record<string, Record<string, Record<string, any>>>;
    const isError = typedErrors?.[arrayFieldName]?.[arrayIndex]?.[fieldName];

    return (

        <FormControl
            isInvalid={!!isError}
            isRequired={true} >
            <InputGroup>
                <Input {...field}
                    {...register(name)}
                    placeholder="Write it here..."
                    type='number'
                    inputMode='decimal'
                />
            </InputGroup>
            <FormErrorMessage>
                {isError?.message}
            </FormErrorMessage>
        </FormControl>
    )
}

export default TankGaugeReadingField
