import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { ticketsApi } from "../tickets/ticketsApi"
import {
  bindLocalEntries,
  cleanLocalEntries,
} from "../timeEntries/localEntriesSlice"
import { customersApi } from "../customers/customersApi"
import { terminalsApi } from "../terminals/terminalsApi"
import { productsApi } from "../products/productsApi"
import { servicesApi } from "../services/servicesApi"
import { locationsApi } from "../terminals/locationsApi"
import { chargesApi } from "../charges/chargesApi"
import { awsApi } from "../aws/awsApi"

export interface DraftProductsValues {
  product: ExistingOrNew
  subsidiary_class: string
  toxic_by_inhalation: boolean
  quantity: number
  packages: number
}

export interface DraftProductPayload {
  draftIndex?: number
  updateDraftProducts: DraftProductsValues[]
}

export interface DraftServicesValues {
  service: ExistingOrNew
  rate: number
  quantity: number
  description: string
}
export interface DraftServicesPayload {
  draftIndex?: number
  updateDraftServices: DraftServicesValues[]
}

export interface DraftChargeValues {
  description: string
  quantity: string
  unit: string
  rate: number
  allow_surcharge: boolean
  amount: number
}

export interface DraftLocationsValues {
  id?: number
  draftIndex?: number
  consignor?: DraftConsignorValues | null
  consignee?: DraftConsigneeValues | null
}
export interface DraftStampDetails {
  draftIndex?: number
  cost_class: number | null,
  minor_class: number | null,
  major_class: number | null,
  other: null,
  digital_sign: string | null
}

export interface Draft {
  number?: string | null
  hasContent?: boolean
  basic?: DraftBasicValues | null
  locations?: DraftLocationsValues | null
  consignor?: DraftConsignorValues | null
  consignee?: DraftConsigneeValues | null
  products: DraftProductsValues[]
  services: DraftServicesValues[]
  pickUp: DraftExtraValues | null
  droppOff: DroppOffValues | null
  attachments: string[] | null
  charges: DraftChargeValues[] | null
  isAskDriverLead: boolean
  stampDetails: DraftStampDetails
}
interface DraftState {
  drafts: Draft[]
}
const initialState: DraftState = {
  drafts: [],
}

const draftSlice = createSlice({
  name: "drafts",
  initialState,
  reducers: {
    discardDraft(state, action) {
      const { draftIndex } = action.payload
      const updatedDrafts = [...state.drafts] // Create a copy of the drafts array
      updatedDrafts.splice(draftIndex, 1) // Remove the draft at draftIndex from the copied array
      return {
        ...state,
        drafts: updatedDrafts, // Update the drafts array in the state with the modified copy
      }
    },
    discardAllDrafts(state) {
      return {
        ...state,
        drafts: [],
      }
    },
    saveDraftBasic(state, action: PayloadAction<DraftBasicValues>) {
      const { draftIndex, ...data } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          basic: data,
        }
      }
    },
    saveDraftLocations(state, action: PayloadAction<DraftLocationsValues>) {
      const { draftIndex, ...data } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          locations: data,
        }
      }
    },
    saveDraftConsignor(state, action: PayloadAction<DraftConsignorValues>) {
      const { draftIndex, ...data } = action.payload

      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          consignor: data,
        }
      }
    },
    saveDraftConsignee(state, action: PayloadAction<DraftConsigneeValues>) {
      const { draftIndex, ...data } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          consignee: data,
        }
      }
    },
    saveDraftProducts(state, action: PayloadAction<DraftProductPayload>) {
      const { draftIndex, updateDraftProducts } = action.payload

      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          products: updateDraftProducts,
        }
      }
    },
    saveDraftServices(state, action: PayloadAction<DraftServicesPayload>) {
      const { draftIndex, updateDraftServices } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          services: updateDraftServices,
        }
      }
    },
    saveDraftPickUp(state, action: PayloadAction<DraftExtraValues>) {
      const { draftIndex, ...data } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          pickUp: data,
        }
      }
    },
    saveDraftDroppOff(state, action: PayloadAction<DroppOffValues>) {
      const { draftIndex, ...data } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          droppOff: data,
        }
      }
    },
    saveDraftStampDetails(state, action: PayloadAction<DraftStampDetails>) {
      const { draftIndex, ...data } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          stampDetails: data,
        }
      }
    },
    saveDraftAttachments(state, action) {
      const { draftIndex, file_keys } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          attachments: file_keys,
        }
      }
    },
    saveDraftCharge(state, action) {
      const { draftIndex, updatedCharges } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          charges: updatedCharges
        }
      }
    },
    saveAskToDriveLead(state, action) {
      const { draftIndex, isAskDriverLead } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          isAskDriverLead: isAskDriverLead
        }
      }
    },
    bindDraftNumber(state, action) {
      const { draftIndex, uniqeId } = action.payload
      if (draftIndex != null) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          hasContent: true,
          number: uniqeId,
        }
      }
    },
  },
})

export const selectDraft = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]
export const selectDraftNumber = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.number
export const selectDraftHasContent = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.hasContent
export const selectDraftBasic = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.basic
export const selectDraftConsignor = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.consignor
export const selectDraftLocations = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.locations
export const selectDraftConsignee = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.consignee
export const selectDraftProducts = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.products
export const selectDraftServices = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.services
export const selectPickUp = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.pickUp
export const selectDroppOff = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.droppOff
export const selectDraftCharges = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.charges
export const selectDraftIsAskDriverLead = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.isAskDriverLead
export const selectDraftStmpDetails = (id: number) => (state: RootState) =>
  state?.drafts?.drafts[id]?.stampDetails
export const createTicketAfterDraft = createAsyncThunk(
  "draft/createTicketAfterDraft",
  async (draftIndex: number, { dispatch, getState, rejectWithValue }) => {
    try {
      const draftState: Draft = (getState() as RootState).drafts.drafts[
        draftIndex
      ]
      const profile_id = (getState() as RootState).session.resource?.profile?.id
      const { terminals, locations } = getState() as RootState;
      const customerId =
        typeof draftState.basic?.customer === "string"
          ? (
            await dispatch(
              customersApi.endpoints.createCustomer.initiate({
                name: draftState.basic?.customer,
              }),
            ).unwrap()
          ).id
          : draftState.basic?.customer

      const localTimeEntriesState = (getState() as RootState).localEntries

      //filter local entries bind to this draft number
      const finalEntries = localTimeEntriesState.entries.filter(
        (item) => item.ticketDraft === draftState?.number,
      )
      const finalLocalEntries = finalEntries.map((item) => {
        return {
          activity: item.activity,
          start: item.start,
          finish: item.finish,
        }
      })
      let consignorTerminalId: number | undefined;
      let consignorLocationId: number | undefined;

      const consignorTerminal = draftState?.locations?.consignor?.terminal || ''
      const consignorLocation = draftState?.locations?.consignor?.location || ''

      try {
        const getLocationEntity = (id: number) => locations?.entities?.[id];
        const getTerminalLocations = (id: number) => terminals?.entities?.[id]?.locations;
        const createTerminal = async (name: string) => {
          const response = await dispatch(terminalsApi.endpoints.createTerminal.initiate({ name, for: "consignor" })).unwrap();
          return response.id;
        };

        const createLocation = async (name: string, terminalId: number) => {
          const response = await dispatch(locationsApi.endpoints.createLocation.initiate({ name, terminal_id: terminalId, for: "consignor" })).unwrap();
          return response.id;
        };

        const isNonEmptyString = (value: any) => typeof value === 'string' && value.trim() !== '';

        if (typeof consignorTerminal === 'number' && typeof consignorLocation === 'number') {
          const currentTerminalLocations = getTerminalLocations(consignorTerminal);
          const isExist = currentTerminalLocations?.some((item) => item.id === consignorLocation);

          if (!isExist) {
            const currentLocation = getLocationEntity(consignorLocation);
            if (!currentLocation || !currentLocation.name) {
              throw new Error("Current location or location name is invalid");
            }
            consignorLocationId = await createLocation(currentLocation?.name, consignorTerminal);
          } else {
            consignorLocationId = consignorLocation;
          }
          consignorTerminalId = consignorTerminal;
        } else if (isNonEmptyString(consignorTerminal) && typeof consignorLocation === 'number') {
          const currentLocation = getLocationEntity(consignorLocation);
          if (!currentLocation || !currentLocation.name) {
            throw new Error("Current location or location name is invalid");
          }
          consignorTerminalId = await createTerminal(consignorTerminal.toString());
          consignorLocationId = await createLocation(currentLocation?.name, consignorTerminalId);
        } else if (isNonEmptyString(consignorTerminal) && isNonEmptyString(consignorLocation)) {
          consignorTerminalId = await createTerminal(consignorTerminal.toString());
          consignorLocationId = await createLocation(consignorLocation.toString(), consignorTerminalId);
        } else if (typeof consignorTerminal === 'number' && typeof isNonEmptyString(consignorLocation)) {
          consignorLocationId = await createLocation(consignorLocation.toString(), consignorTerminal);
          consignorTerminalId = consignorTerminal;
        }

      } catch (error) {
        console.error("Error from ticket consignor:", error);
        throw error;
      }

      let consigneeTerminalId: number | undefined;
      let consigneeLocationId: number | undefined;
      const consigneeTerminal = draftState?.locations?.consignee?.terminal || ''
      const consigneeLocation = draftState?.locations?.consignee?.location || ''

      try {
        const getLocationEntity = (id: number) => locations?.entities?.[id];
        const getTerminalLocations = (id: number) => terminals?.entities?.[id]?.locations;

        const createTerminal = async (name: string) => {
          const response = await dispatch(terminalsApi.endpoints.createTerminal.initiate({ name, for: "consignee" })).unwrap();
          return response.id;
        };
        const createLocation = async (name: string, terminalId: number) => {
          const response = await dispatch(locationsApi.endpoints.createLocation.initiate({ name, terminal_id: terminalId, for: "consignee" })).unwrap();
          return response.id;
        };
        const isNonEmptyString = (value: any) => typeof value === 'string' && value.trim() !== '';
        if (typeof consigneeTerminal === 'number' && typeof consigneeLocation === 'number') {
          const currentTerminalLocations = getTerminalLocations(consigneeTerminal);
          const isExist = currentTerminalLocations?.some((item) => item.id === consigneeLocation);

          if (!isExist) {
            const currentLocation = getLocationEntity(consigneeLocation);
            if (!currentLocation || !currentLocation.name) {
              throw new Error("Current location or location name is invalid");
            }
            consigneeLocationId = await createLocation(currentLocation?.name, consigneeTerminal);
          } else {
            consigneeLocationId = consigneeLocation;
          }
          consigneeTerminalId = consigneeTerminal;
        } else if (isNonEmptyString(consigneeTerminal) && typeof consigneeLocation === 'number') {
          const currentLocation = getLocationEntity(consigneeLocation);
          if (!currentLocation || !currentLocation.name) {
            throw new Error("Current location or location name is invalid");
          }
          consigneeTerminalId = await createTerminal(consigneeTerminal.toString());
          consigneeLocationId = await createLocation(currentLocation?.name, consigneeTerminalId);
        } else if (isNonEmptyString(consigneeTerminal) && isNonEmptyString(consigneeLocation)) {
          consigneeTerminalId = await createTerminal(consigneeTerminal.toString());
          consigneeLocationId = await createLocation(consigneeLocation.toString(), consigneeTerminalId);
        } else if (typeof consigneeTerminal === 'number' && isNonEmptyString(consigneeLocation)) {
          consigneeLocationId = await createLocation(consigneeLocation.toString(), consigneeTerminal);
          consigneeTerminalId = consigneeTerminal;
        }

      } catch (error) {
        console.error("Error from ticket consignee:", error);
        throw error;
      }

      const equipmentDetails = draftState.basic?.equipments.map(
        (equipment) => ({
          equipment_id: equipment,
        }),
      )

      const productDetails = draftState.products
        ? await Promise.all(
          draftState.products.map(async (item) => {
            const productId =
              typeof item.product === "string"
                ? (
                  await dispatch(
                    productsApi.endpoints.createProduct.initiate({
                      name: item.product,
                    }),
                  ).unwrap()
                ).id
                : item.product

            return {
              product_id: productId,
              toxic_by_inhalation: item.toxic_by_inhalation,
              subsidiary_class: item.subsidiary_class,
              quantity: item.quantity,
              packages: item.packages,
            }
          }),
        )
        : []

      const serviceDetails = draftState.services
        ? await Promise.all(
          draftState.services.map(async (item) => {
            const serviceId =
              typeof item.service === "string"
                ? (
                  await dispatch(
                    servicesApi.endpoints.createService.initiate({
                      name: item.service,
                    }),
                  ).unwrap()
                ).id
                : item.service

            return {
              service_id: serviceId,
              quantity: item.quantity,
              rate: item.rate,
              description: item.description,
            }
          }),
        )
        : []

      const lastContainedProductId =
        typeof draftState.pickUp?.residue_last_contained === "string" &&
          draftState.pickUp?.residue_last_contained.trim().length > 0
          ? (
            await dispatch(
              productsApi.endpoints.createProduct.initiate({
                name: draftState.pickUp?.residue_last_contained,
              }),
            ).unwrap()
          ).id
          : draftState.pickUp?.residue_last_contained

      const sign = draftState.stampDetails?.digital_sign
      const digital_signature = sign
        ? await (async () => {
          try {
            const blob = await (await fetch(sign)).blob();
            const contentType = blob.type;
            const fileObjects = {
              id: 1,
              object_key: `${draftState?.number}/signature/${draftState?.number}.png`,
              mime_type: contentType,
            };
            const presignedUrlResponse = await dispatch(
              awsApi.endpoints.getPresignedUrl.initiate({ object_keys: [fileObjects] })
            );
            if (!('data' in presignedUrlResponse)) throw new Error("Failed to get presigned URL");
            const { url, object_key: file_key } = presignedUrlResponse.data?.[0];
            const file = { url, file: blob };
            const uploadResponse = await dispatch(awsApi.endpoints.uploadFileToS3.initiate(file));
            if (!uploadResponse) throw new Error("Failed to upload file to S3");
            return file_key;
          } catch (error) {
            console.error("Error during digital signature process:", error);
            return null;
          }
        })()
        : null;

      const ticketPayload = {
        driver_id: profile_id,
        status: "submitted",
        reference_date: draftState.basic?.reference_date,
        customer_id: customerId,
        number: draftState?.number,
        consignor_terminal_id: consignorTerminalId,
        consignor_location_id: consignorLocationId,
        consignor_lsdprefix: draftState?.locations?.consignor?.lsdprefix,
        consignor_lsd: draftState?.locations?.consignor?.lsd,
        consignee_terminal_id: consigneeTerminalId,
        consignee_location_id: consigneeLocationId,
        consignee_lsdprefix: draftState?.locations?.consignee?.lsdprefix,
        consignee_lsd: draftState?.locations?.consignee?.lsd,
        emergency_contact: draftState?.basic?.emergency_contact,
        billing_email: draftState?.basic?.billing_email,
        consignor_contact: draftState?.basic?.consignor_contact,
        consignor_phone: draftState?.basic?.consignor_phone,
        confirmed_empty: draftState.pickUp?.confirmed_empty,
        residue_last_contained: lastContainedProductId,
        estimated_volume: draftState?.pickUp?.estimated_volume,
        sequence: draftState?.pickUp?.sequence,
        sw_percent: draftState?.pickUp?.sw_percent,
        tank_gauge_details: draftState?.pickUp?.tank_gauge_details,
        actual_volume: draftState?.droppOff?.actual_volume,
        work_description: draftState?.droppOff?.work_description,
        equipmentdetails: equipmentDetails,
        productdetails: productDetails,
        servicedetails: serviceDetails,
        attachments: draftState?.attachments,
        driver_lead_add_charges: draftState?.isAskDriverLead || false,
        timeentries: finalLocalEntries,
        digital_signed: digital_signature ? true : false,
        signature: digital_signature,
        cost_class: draftState.stampDetails?.cost_class,
        minor_class: draftState.stampDetails?.minor_class,
        major_class: draftState.stampDetails?.major_class,
        other: draftState.stampDetails?.other,
      }

      const newTicket = await dispatch(
        ticketsApi.endpoints.createTicket.initiate(ticketPayload),
      ).unwrap()

      console.log("New Ticket", newTicket)

      // // time entries of this ticket , and at the end clean local time entries of this ticket
      // dispatch(
      //   bindLocalEntries({
      //     ticketId: newTicket.id,
      //     draftNumber: draftState?.number ?? null,
      //   }),
      // )

      //bind charges 
      draftState?.charges &&
        await Promise.all(
          draftState?.charges.map(async (charge) => {
            const payload = {
              description: charge.description,
              unit: charge.unit,
              rate: charge.rate,
              quantity: charge.quantity,
              amount: charge.amount,
              ticket: newTicket.id,
              allow_surcharge: charge.allow_surcharge ?? false,
            }
            await dispatch(
              chargesApi.endpoints.createCharge.initiate(payload),
            )
          })
        )
      dispatch(discardDraft({ draftIndex: draftIndex }))
      dispatch(cleanLocalEntries({ draftNumber: draftState?.number ?? null }))
      return newTicket
    } catch (error) {
      console.error("Failed to create ticket:", error)
      return rejectWithValue(error)
    }
  },
)

export const {
  discardDraft,
  discardAllDrafts,
  saveDraftBasic,
  saveDraftConsignor,
  saveDraftLocations,
  saveDraftConsignee,
  saveDraftProducts,
  saveDraftServices,
  saveDraftPickUp,
  saveDraftDroppOff,
  saveDraftAttachments,
  saveDraftStampDetails,
  saveDraftCharge,
  saveAskToDriveLead,
  bindDraftNumber,
} = draftSlice.actions

export default draftSlice.reducer
