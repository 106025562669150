import { Box, VStack, ButtonGroup, Button, Flex, InputGroup, InputLeftAddon, Input, Switch, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, useDisclosure } from "@chakra-ui/react"
import { ViewHeader } from "./parts/ViewHeader"
import { ViewStream } from "./parts/ViewStream"
import { SearchInput } from "../../components/SearchBox"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppSelector, useNetworkCheck } from "../../app/hooks"
import { selectSession } from "../../features/session/sessionSlice"
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from "react"
import DatePicker from 'react-datepicker'
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { selectAllCustomers } from "../../features/customers/customersSlice"
import { FilterValues } from "../../pages/user/ApproveSubmittedTimeEntries"
import { TICKET_STATUS_OPTIONS } from "../../features/tickets/ticket_choices"
import CustomSelect from "../../components/forms/fields/CustomeSelectField"
import { selectAllActivities } from "../../features/activities/activitySlice"
import { useListActivitiesQuery } from "../../features/activities/activitiesApi"
import { PuffLoader } from "react-spinners"
import moment from "moment"
interface ListViewProps {
    children: React.ReactNode
    title: string
    filter_label?: string
    subtitle?: string
    isLoading?: boolean
    filters: FilterValues
    setFilters?: Dispatch<SetStateAction<FilterValues>>;
    handleApplyFilter?(): void
    handleResetFilter?(): void
}
type OptionType = { value: string | number; label: string; };
type EntryType = "ticketTime" | "nonTicketTime" | "all";

const ticketEntryTypeOption: { label: string; value: EntryType }[] = [
    { label: "All", value: "all" },
    { label: "Ticket time", value: "ticketTime" },
    { label: "Non-ticket Time", value: "nonTicketTime" }
]

export function ListView({
    title,
    filter_label,
    subtitle,
    children,
    isLoading,
    filters,
    setFilters = () => { }, // default value
    handleApplyFilter,
    handleResetFilter
}: ListViewProps) {
    useListActivitiesQuery()
    const navigate = useNavigate()
    const { isOnline } = useNetworkCheck()
    const session = useAppSelector(selectSession)
    const role = session?.profile.role
    const pathName = useLocation().pathname
    const { isOpen, onOpen, onClose } = useDisclosure();
    const customers = useAppSelector(selectAllCustomers)
    const activities = useAppSelector(selectAllActivities)
    const btnRef = useRef<HTMLButtonElement | null>(null);
    const { activity, dateRange, ticket, is_approved, filterByName, customer, status, entryType } = filters
    const [startDate, endDate] = dateRange;

    // Memoize customer options
    const customerSelectOptions: OptionType[] = useMemo(() =>
        customers.filter(customer => customer?.profile)
            .map(customer => ({ value: customer.id, label: customer.name })), [customers]);

    const customerRoleSelectOptions = useMemo(() => {
        return TICKET_STATUS_OPTIONS.filter(option =>
            option.value !== "submitted" && option.value !== "unapproved"
        )
    }, [])

    const getActivityOptions = useCallback(() => {
        return activities
            .filter((item) => entryType === "all" || item.description_field === (entryType === "nonTicketTime"))
            .map((item) => ({ value: item.name, label: item.name }));
    }, [activities, entryType]);

    const isDriverLeadWithApproveTime = role === "driver-lead" && pathName.includes("/user/approve-time-entries");
    const isSubmittedTimePage = pathName.includes("user/tracking")

    const selectValue = isDriverLeadWithApproveTime ? activity : status;
    const selectPlaceholder = isDriverLeadWithApproveTime ? "Select Activity" : "Select Ticket Status";
    const selectOptions = isDriverLeadWithApproveTime ? getActivityOptions() : TICKET_STATUS_OPTIONS;

    const handleSelectChange = (value: OptionType) =>
        setFilters(prev => ({ ...prev, [isDriverLeadWithApproveTime ? 'activity' : 'status']: value || prev[isDriverLeadWithApproveTime ? 'activity' : 'status'] }));

    const handleCustomerChange = (value: OptionType | null) =>
        setFilters(prev => ({ ...prev, customer: value }));

    const handleTicketType = (value: "ticketTime" | "nonTicketTime" | "all") =>
        setFilters((prev) => { return { ...prev, entryType: value, activity: [], customer: null } })

    const getSearchLabel = (role: string, isDriverLeadWithApproveTime: boolean) => {
        if (isDriverLeadWithApproveTime) return "Search by driver name";
        if (role === "driver-lead") return "Search by driver name or customer name";
        return "Search by customer name";
    };

    const handleReset = () => handleResetFilter?.();
    const handleFilter = () => { handleApplyFilter?.(); onClose(); };


    return (
        <VStack w="full" h="full">
            <ViewStream>
                <Flex w="100%" justifyContent="space-between" alignContent="center" >
                    <ViewHeader subtitle={subtitle}>{title}</ViewHeader>
                    <ButtonGroup w="full" justifyContent="flex-end">
                        < Button
                            size={["xs", "sm", "md"]}
                            ref={btnRef}
                            p={4}
                            colorScheme="blue"
                            onClick={onOpen}
                            leftIcon={<KnownIcon name="filter" />}
                            isDisabled={!isOnline}
                        >
                            Filter
                        </Button>
                        {
                            role !== "customer" &&
                            <Button
                                size={["xs", "sm", "md"]}
                                p={4} colorScheme="blue"
                                onClick={() => navigate("/user/home")}
                                leftIcon={<KnownIcon name="back" />}
                            >
                                Home
                            </Button>
                        }
                        <Drawer
                            isOpen={isOpen}
                            placement='right'
                            onClose={onClose}
                            finalFocusRef={btnRef}
                            size="sm"
                            allowPinchZoom={true}
                        >
                            <DrawerOverlay />
                            <DrawerContent >
                                <DrawerCloseButton />
                                <DrawerHeader>{filter_label}</DrawerHeader>
                                <DrawerBody>
                                    <VStack w="full" gap={4}>
                                        {
                                            isDriverLeadWithApproveTime &&
                                            <Box w="full" fontSize="md" >
                                                <ButtonGroup isAttached size={["xs", "sm"]} >
                                                    {ticketEntryTypeOption.map((item, index) => (
                                                        <Button
                                                            key={index}
                                                            colorScheme={entryType === item.value ? 'blue' : 'gray'}
                                                            onClick={(e) => handleTicketType(item.value)}
                                                        >
                                                            {item.label}
                                                        </Button>
                                                    ))}
                                                </ButtonGroup>
                                            </Box>
                                        }
                                        {
                                            !isSubmittedTimePage ?
                                                <>
                                                    {
                                                        role !== "customer" &&
                                                        <Box w="full">
                                                            <SearchInput
                                                                value={filterByName || ''}
                                                                setFilterByName={setFilters}
                                                                label={getSearchLabel(role, isDriverLeadWithApproveTime)}
                                                            />
                                                        </Box>
                                                    }
                                                    <Box w="full">
                                                        {
                                                            isDriverLeadWithApproveTime && (entryType === "ticketTime" || entryType === "all") &&
                                                            <CustomSelect
                                                                value={customer ?? null}
                                                                placeholder="Select customer"
                                                                options={customerSelectOptions}
                                                                onChange={handleCustomerChange}
                                                                isSearchable={true}
                                                                isMulti={false}
                                                            />
                                                        }
                                                    </Box>
                                                    <Box w="full">
                                                        <CustomSelect
                                                            value={selectValue ?? null}
                                                            placeholder={selectPlaceholder}
                                                            options={role !== 'customer' ? selectOptions : customerRoleSelectOptions}
                                                            onChange={handleSelectChange}
                                                            isSearchable={false}
                                                            isMulti
                                                        />
                                                    </Box>
                                                    <Box w="full">
                                                        <InputGroup isolation={"auto"} mt={2} w="full">
                                                            <InputLeftAddon h={"2.5rem"}>
                                                                <KnownIcon name="calendar" />
                                                            </InputLeftAddon>
                                                            <DatePicker
                                                                placeholderText="Select date range"
                                                                selectsRange={true}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                onChange={(update) => {
                                                                    setFilters(prev => ({ ...prev, dateRange: update }))
                                                                }}
                                                                isClearable={true}
                                                                maxDate={moment().toDate()}
                                                            />
                                                        </InputGroup>
                                                    </Box>
                                                    {
                                                        isDriverLeadWithApproveTime && (
                                                            <>
                                                                <Box w="full" fontSize="md" >
                                                                    <Flex gap={4} alignItems="center">
                                                                        <Box>Approved Time:</Box>
                                                                        <Switch isChecked={is_approved} size="md" onChange={(e) => {
                                                                            const { checked } = e.target
                                                                            setFilters((prev) => { return { ...prev, is_approved: checked } })
                                                                            if (checked) setFilters(prev => { return { ...prev, ticket: null } })
                                                                        }} />
                                                                    </Flex>
                                                                </Box>
                                                                <Box w="full">
                                                                    {
                                                                        (entryType === "ticketTime" || entryType === "all") &&
                                                                        <Box w="full">
                                                                            <Input
                                                                                value={ticket || ""}
                                                                                name="ticket"
                                                                                placeholder="Ticket Number"
                                                                                onChange={(e) => setFilters(prev => { return { ...prev, ticket: Number(e.target.value) } })}
                                                                                type="number"
                                                                            />
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            </>
                                                        )
                                                    }
                                                </> :
                                                <>
                                                    <Box w="full">
                                                        <InputGroup isolation={"auto"} mt={2} w="full">
                                                            <InputLeftAddon h={"2.5rem"}>
                                                                <KnownIcon name="calendar" />
                                                            </InputLeftAddon>
                                                            <DatePicker
                                                                placeholderText="Select date range"
                                                                selectsRange={true}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                onChange={(update) => {
                                                                    setFilters(prev => ({ ...prev, dateRange: update }))
                                                                }}
                                                                isClearable={true}
                                                                maxDate={moment().toDate()}
                                                            />
                                                        </InputGroup>
                                                    </Box>
                                                </>
                                        }
                                    </VStack>
                                </DrawerBody>
                                <DrawerFooter>
                                    <Button variant='outline' mr={3} onClick={handleReset}>
                                        Reset
                                    </Button>
                                    <Button colorScheme="blue" onClick={handleFilter}>Apply</Button>
                                </DrawerFooter>
                            </DrawerContent>
                        </Drawer>
                    </ButtonGroup>
                </Flex>
                {isLoading ?
                    <Flex w="full" h="full" justifyContent="center" alignItems="center">
                        <PuffLoader
                            color="#3D82CE"
                            size={80}
                        />
                    </Flex>
                    :
                    children
                }
            </ViewStream>
        </VStack>
    )
}
