import { useEffect, useRef } from "react"
import { VStack, Heading, Box, Text, Button } from "@chakra-ui/react"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { logoutUser, selectIsLoggedIn } from "./sessionSlice"
import { Link } from "react-router-dom"
import { bolmintApi } from "../../app/api"

export function SessionLogout() {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isTriggered = useRef(false)
  useEffect(() => {
    if (!isTriggered.current) {
      isTriggered.current = true
      dispatch(bolmintApi.util.resetApiState());
      dispatch(logoutUser())
    }
  }, [dispatch])

  const message = isLoggedIn ? "Logging out..." : "You logged out!"

  return (
    <VStack width="full" gap={6}>
      <Heading as="h5" size="md">
        Logout
      </Heading>
      <Box w="full">
        <Text align="center">{message}</Text>
      </Box>
      <Button
        as={Link}
        to="/auth/login"
      >
        Login
      </Button>
    </VStack>
  )
}
