import { FieldArray } from "../../components/forms/FieldArray"
import { InputStreak } from "../../components/forms/InputStreak"
import { DateField } from "../../components/forms/fields/DateField"
import { InputField } from "../../components/forms/fields/InputField"
import { PhoneField } from "../../components/forms/fields/PhoneField"
import { CustomerAutocomplete } from "../customers/CustomerAutocomplete"
import { EquipmentSelect } from "../equipments/EquipmentSelect"
import EmailField from "../../components/forms/fields/EmailField"
import { useFormContext } from "react-hook-form"
import { useEffect, useState } from "react"

interface BasicFormProps {
  onRemove?(index: number): void
}

export function BasicForm({ onRemove }: BasicFormProps) {
  const { watch } = useFormContext()
  const equipmentsValues = watch("equipments")
  const [isvalue, setIsValue] = useState<null | number>(null)
  useEffect(() => {
    setIsValue(watch("equipments")?.slice(-1).pop() || null)
  }, [equipmentsValues])

  return (
    <>
      <DateField
        label="Date"
        name="reference_date"
        helper="Enter the date for this ticket"
      />
      <CustomerAutocomplete
        label="Customer"
        name="customer"
        registerOptions={{ required: "Please select customer" }}
      />
      <InputStreak
        label="Consignor Contact"
        helper="Enter emergency name and phone"
      >
        <InputField name="consignor_contact" helper="Name" />
        <PhoneField name="consignor_phone" helper="Phone number" />
      </InputStreak>
      <InputStreak label="Billing Email Address" >
        <EmailField name="billing_email" helper="Billing email address" />
      </InputStreak>
      <InputStreak label="Emergency Contact" helper="Enter Emergency contact">
        <PhoneField name="emergency_contact" helper="Emergency contact" />
      </InputStreak>
      <FieldArray label="Equipment" name="equipments" onRemove={onRemove} isValue={isvalue} setIsValue={setIsValue}>
        {(fieldName) => <EquipmentSelect name={fieldName} setIsValue={setIsValue} />}
      </FieldArray>
    </>
  )
}
