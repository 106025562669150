import { useEffect, useRef, useState } from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  useMediaQuery,
  Box,
  useDisclosure,

} from "@chakra-ui/react"
import { ErrorBox } from "../../components/ErrorBox"
import { DetailView } from "../../layout/views/DetailView"
import { BasicPanel } from "../../features/tickets/BasicPanel"
import { ProductsPanel } from "../../features/draft/ProductsPanel"
import { PickUpPanel } from "../../features/tickets/PickUpPanel"
import { useListCustomersQuery } from "../../features/customers/customersApi"
import { useListTerminalsQuery } from "../../features/terminals/terminalsApi"
import { useListEquipmentsQuery } from "../../features/equipments/equipmentsApi"
import { useListProductsQuery } from "../../features/products/productsApi"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Button } from "@chakra-ui/react"
import {
  bindDraftNumber,
  createTicketAfterDraft,
  discardDraft,
  saveDraftAttachments,
  saveDraftBasic,
  saveDraftDroppOff,
  saveDraftPickUp,
  saveDraftLocations,
  saveDraftProducts,
  saveDraftServices,
  selectDraftBasic,
  selectPickUp,
  selectDraftHasContent,
  selectDraftLocations,
  selectDraftNumber,
  selectDraftProducts,
  selectDraftServices,
  selectDroppOff,
  selectDraftStmpDetails,
  saveDraftStampDetails,
} from "../../features/draft/draftSlice"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { DriverHoursPanel } from "../../features/timeEntries/DriverHoursPanel"
import { ServicesPanel } from "../../features/draft/ServicesPanel"
import { useListServicesQuery } from "../../features/services/servicesApi"
import { customAlphabet } from "nanoid"
import {
  LocalEntry,
  cleanLocalEntries,
  selectLocalEntries,
} from "../../features/timeEntries/localEntriesSlice"
import { AttachmentField } from "../../components/forms/fields/AttachmentField"
import { Panel } from "../../components/Panel"
import { useDestroyAttachmentsMutation } from "../../features/aws/awsApi"
import { DraftLocationsPanel } from "../../features/locations/DraftLocationsPanel"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { DroppOffPanel } from "../../features/tickets/DroppOffPanel"
import { useNetworkCheck, useScrollTo } from "../../app/hooks"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useListActivitiesQuery } from "../../features/activities/activitiesApi"
import { useListLocationsQuery } from "../../features/terminals/locationsApi"
import { ModalForm } from "../../components/forms/ModalForm"
import StampForm from "../../features/tickets/StampForm"
import OverlayLoader from "../../components/OverlayLoader"

export function DraftPage() {
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false)
  const [draftDriverHourEntries, setDraftDriverHourEntries] = useState<LocalEntry[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [formIsSubmitting, setFormIsSubmiting] = useState<boolean>(false)
  const [draftErrorMessage, setDraftErrorMessage] = useState<string[]>([])

  const { isOpen, onClose, onOpen } = useDisclosure()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { isOnline } = useNetworkCheck()
  const [setRef, scrollTo] = useScrollTo()
  const cancelRef = useRef(null)
  const [isSmallScreen] = useMediaQuery("(max-width: 850px)")
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"

  useListCustomersQuery()
  useListTerminalsQuery()
  useListEquipmentsQuery()
  useListProductsQuery()
  useListServicesQuery()
  useListLocationsQuery()
  useListActivitiesQuery()

  const [destroyAttachments] = useDestroyAttachmentsMutation()
  const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
  const draftHasContent = useAppSelector(selectDraftHasContent(draftIndex))
  const localEntries = useAppSelector(selectLocalEntries)
  const draftBasic = useAppSelector(selectDraftBasic(draftIndex))
  const draftLocations = useAppSelector(selectDraftLocations(draftIndex))
  const draftPickUp = useAppSelector(selectPickUp(draftIndex))
  const droppOff = useAppSelector(selectDroppOff(draftIndex))
  const draftProducts = useAppSelector(selectDraftProducts(draftIndex)) ?? []
  const draftServices = useAppSelector(selectDraftServices(draftIndex)) ?? []
  const draftNumber = useAppSelector(selectDraftNumber(draftIndex))
  const draftStampDetails = useAppSelector(selectDraftStmpDetails(draftIndex))

  /*adding unique id to draft , first check for draft number in draft state , 
   * if there is content and there is no unique number then dispatch bindDraftNumber action
   */
  const nanoid = customAlphabet("1234567890", 6)
  useEffect(() => {
    if (!draftNumber
      // &&(draftHasContent ?? false)
    ) {
      let uniqeId
      do {
        uniqeId = parseInt(nanoid(), 10)
      } while (uniqeId <= 250000)
      dispatch(bindDraftNumber({ draftIndex, uniqeId }))
    }
  }, [draftNumber, draftHasContent])

  //check for there is time entries in current draft
  useEffect(() => {
    if (draftNumber) {
      const entries = localEntries.filter(
        (item: LocalEntry) => item.ticketDraft === draftNumber,
      )
      setDraftDriverHourEntries(entries)
    }
  }, [draftNumber, localEntries])

  const handleBasicSave = (data: any) => {
    const filteredEquipments = data.equipments.filter((item: any) => item && typeof item === "number")
    const payload = {
      draftIndex,
      ...data,
      equipments: filteredEquipments
    }
    dispatch(saveDraftBasic(payload))
    scrollTo("draftLocationsForm")
  }
  const handleLocationsSave = (data: any) => {
    dispatch(saveDraftLocations({ draftIndex, ...data }))
    scrollTo("productForm")
  }
  const handleProductSave = (data: any) => {
    const updateDraftProducts = [...draftProducts]
    updateDraftProducts.push(data)
    dispatch(saveDraftProducts({ draftIndex, updateDraftProducts }))
  }
  const handlePickUpSave = (data: any) => {
    dispatch(saveDraftPickUp({ draftIndex, ...data }))
    scrollTo("dropOffForm")
  }
  const handleDroppOffSave = (data: any) => {
    dispatch(saveDraftDroppOff({ draftIndex, ...data }))
    scrollTo("driverHoursForm")
  }
  const handleServiceSave = (data: any) => {
    const updateDraftServices = [...draftServices]
    updateDraftServices.push(data)
    dispatch(saveDraftServices({ draftIndex, updateDraftServices }))
  }
  const handleSaveAttachments = (file_keys: string[]) => {
    dispatch(saveDraftAttachments({ draftIndex, file_keys }))
  }
  const handleProductRemove = (index: number) => {
    const updateDraftProducts = [...draftProducts]
    updateDraftProducts.splice(index, 1)
    dispatch(saveDraftProducts({ draftIndex, updateDraftProducts }))
  }
  const handleServiceRemove = (index: number) => {
    const updateDraftServices = [...draftServices]
    updateDraftServices.splice(index, 1)
    dispatch(saveDraftServices({ draftIndex, updateDraftServices }))
  }
  const handelStampDetails = (data: any) => {
    dispatch(saveDraftStampDetails({ draftIndex, ...data }))
  }
  const handleGoHome = () => {
    navigate("/user/home")
  }
  const handleAddCharges = () => {
    navigate(`/user/draft/${draftIndex + 1}/charges`)
  }
  const handleDiscard = () => {
    setIsDiscardDialogOpen(true)
  }

  const handleConfirmDiscard = () => {
    console.log("Discarding draft...")
    dispatch(discardDraft({ draftIndex: draftIndex }))
    dispatch(cleanLocalEntries({ draftNumber: draftNumber }))
    navigate("/user/home")
    setIsDiscardDialogOpen(false)
    /* remove files related to this draft from aws */
    draftNumber && destroyAttachments(draftNumber)
  }
  const handleCancelDiscard = () => {
    setIsDiscardDialogOpen(false)
  }
  const handleSubmitDraft = async () => {
    setFormIsSubmiting(true)
    try {
      await dispatch(createTicketAfterDraft(draftIndex) as any).unwrap()
      navigate("/user/home")
    } catch (error) {
      const err = error as FetchBaseQueryError;
      const errors = Object.entries(err?.data as object).map(([key, value]) => {
        return value.message
      })
      setShow(true)
      setDraftErrorMessage(errors)
      window.scrollTo({ top: 0, behavior: "smooth" })
    } finally {
      setFormIsSubmiting(false)
    }
  }

  useEffect(() => {
    // Scroll to bottom if the scrollToBottom flag is true
    if (location.state?.scrollToBottom) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [location.state]);

  return (
    <DetailView
      title="Ticket Draft"
      subtitle="This ticket has not yet been saved to the cloud"
    >
      {formIsSubmitting && <OverlayLoader />}
      <ErrorBox show={show} message={draftErrorMessage} />
      <BasicPanel defaultValues={draftBasic} onSave={handleBasicSave} />
      <Box w="full" ref={setRef('draftLocationsForm')}>
        <DraftLocationsPanel
          defaultValues={draftLocations}
          onSave={handleLocationsSave}
        />
      </Box>
      <Box w="full" ref={setRef('productForm')}>
        <ProductsPanel
          defaultValues={draftProducts}
          onSave={handleProductSave}
          onRemove={handleProductRemove}
        />
      </Box>
      <Box w="full" ref={setRef('pickUpForm')}>
        <PickUpPanel
          defaultValues={draftPickUp}
          onSave={handlePickUpSave}
        />
      </Box>
      <Button
        as={draftNumber ? Link : "button"}
        {...(draftNumber
          ? { to: `/print/draft/${draftIndex + 1}?withCharge=${false}` }
          : {})}
        colorScheme="blue"
        size={["xs", "sm"]}
        w={isSmallScreen ? "45%" : "auto"}
        rightIcon={<KnownIcon name="roundDollar" size={5} />}
        isDisabled={draftNumber ? false : true}
        alignSelf={"flex-end"}
        p={4}
      >
        Print without
      </Button>
      <Box w="full" ref={setRef('dropOffForm')}>
        <DroppOffPanel defaultValues={droppOff} onSave={handleDroppOffSave} />
      </Box>
      <Box w="full" ref={setRef('driverHoursForm')}>
        <DriverHoursPanel
          draftNumber={draftNumber || ""}
          draftDriverHourEntries={draftDriverHourEntries}
        />
      </Box>
      <Box w="full" ref={setRef('servicesForm')}>
        <ServicesPanel
          defaultValues={draftServices}
          onSave={handleServiceSave}
          onRemove={handleServiceRemove}
        />
      </Box>
      <Panel title="Attachments">
        <AttachmentField
          draftId={draftNumber ?? null}
          onSaveToDraft={handleSaveAttachments}
        />
      </Panel>
      <Flex
        w="full"
        flexDirection={isSmallScreen ? "column" : "row"}
        gap={isSmallScreen ? 2 : 3}
        justifyContent={isSmallScreen ? "center" : "flex-end"}
        alignItems={isSmallScreen ? "center" : "flex-end"}
      >
        <Button
          as={draftNumber ? Link : "button"}
          {...(draftNumber
            ? { to: `/print/draft/${draftIndex + 1}?withCharge=${false}` }
            : {})}
          colorScheme="blue"
          size={["xs", "sm"]}
          w={isSmallScreen ? "45%" : "auto"}
          rightIcon={<KnownIcon name="roundDollar" size={5} />}
          isDisabled={draftNumber ? false : true}
          p={4}
        >
          Print without
        </Button>
        <Button
          colorScheme="blue"
          size={["xs", "sm"]}
          onClick={handleAddCharges}
          w={isSmallScreen ? "45%" : "auto"}
          isDisabled={draftNumber ? false : true}
          p={4}
        >
          Add Charges
        </Button>
        <Button
          as={draftNumber ? Link : "button"}
          {...(draftNumber
            ? { to: `/print/draft/${draftIndex + 1}?withCharge=${true}` }
            : {})}
          colorScheme="blue"
          size={["xs", "sm"]}
          w={isSmallScreen ? "45%" : "auto"}
          rightIcon={<KnownIcon name="roundDollar" size={5} />}
          isDisabled={draftNumber ? false : true}
          p={4}
        >
          Print with
        </Button>
        <Button
          colorScheme="blue"
          onClick={() => onOpen()}
          size={["xs", "sm"]}
          p={4}
          w={isSmallScreen ? "45%" : "auto"}
          isDisabled={draftNumber ? false : true}
        >
          Signature
        </Button>
        <Button
          colorScheme="red"
          size={["xs", "sm"]}
          onClick={handleDiscard}
          w={isSmallScreen ? "45%" : "auto"}
          p={4}
        >
          Discard
        </Button>
        <Button
          colorScheme="blue"
          size={["xs", "sm"]}
          onClick={handleSubmitDraft}
          w={isSmallScreen ? "45%" : "auto"}
          p={4}
          isDisabled={draftNumber && isOnline ? false : true}
          isLoading={formIsSubmitting}
        >
          Submit
        </Button>
        <Button
          colorScheme="blue"
          size={["xs", "sm"]}
          onClick={handleGoHome}
          w={isSmallScreen ? "45%" : "auto"}
          p={4}
          leftIcon={<KnownIcon name="back" />}
        >
          Home
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isDiscardDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCancelDiscard}
        size={["xs", "sm"]}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Discard Draft
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to discard this draft? This action cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleCancelDiscard}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDiscard} ml={3}>
                Discard
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <ModalForm
        title={`Stamp details`}
        defaultValues={draftStampDetails}
        isOpen={isOpen}
        size={modalSize}
        onClose={onClose}
        onCancel={onClose}
        onSave={handelStampDetails}
      >
        <StampForm />
      </ModalForm>
    </DetailView>
  )
}
