import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks';
import { DraftChargeValues, saveAskToDriveLead, saveDraftCharge, selectDraftCharges, selectDraftIsAskDriverLead, selectDraftNumber, selectDraftProducts, selectDraftServices, selectDroppOff } from '../../features/draft/draftSlice';
import { DetailView } from '../../layout/views/DetailView';
import { Panel } from '../../components/Panel';
import { DraftVolumeTable } from '../../features/draft/DraftVolumeTable';
import { LocalEntry, selectLocalEntries } from '../../features/timeEntries/localEntriesSlice';
import DraftTimeEntriesAggregated from '../../features/draft/DraftTimeEntriesAggregated';
import DraftTicketServicesOverview from '../../features/draft/DraftServicesOverview';
import DraftChargePanelOverview from '../../features/draft/DraftChargePanelOverview';
import { useDispatch } from 'react-redux';
import { Button, Flex, HStack, Switch, Text } from '@chakra-ui/react';
import { selectSession } from '../../features/session/sessionSlice';

export function DraftCharge() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const userRole = useAppSelector(selectSession)?.profile.role
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const draftNumber = useAppSelector(selectDraftNumber(draftIndex))
    const droppOff = useAppSelector(selectDroppOff(draftIndex))
    const draftServices = useAppSelector(selectDraftServices(draftIndex)) ?? []
    const draftCharges = useAppSelector(selectDraftCharges(draftIndex)) ?? []
    const isAskDriverLead = useAppSelector(selectDraftIsAskDriverLead(draftIndex)) ?? false
    const localEntries = useAppSelector(selectLocalEntries)
    const [draftDriverHourEntries, setDraftDriverHourEntries] = useState<
        LocalEntry[]
    >([])
    //check for there is time entries in current draft
    useEffect(() => {
        if (draftNumber) {
            const entries = localEntries.filter(
                (item: LocalEntry) => item.ticketDraft === draftNumber,
            )
            setDraftDriverHourEntries(entries)
        }
    }, [draftNumber, localEntries])

    const handleChargeSave = (data: any) => {
        const { description, quantity, rate, unit, allow_surcharge, amount } = data
        //make unit capitalize first letter
        const capitalizedUnit = unit.charAt(0).toUpperCase() + unit.slice(1)
        let calculatedAmount;
        if (unit === "%") {
            const calculateAmount = rate * quantity / 100
            calculatedAmount = calculateAmount
        } else {
            calculatedAmount = rate * quantity
        }

        const finalPayload: DraftChargeValues = {
            rate: rate,
            unit: capitalizedUnit,
            quantity: quantity,
            description: description,
            allow_surcharge: allow_surcharge,
            amount: calculatedAmount
        }

        const updatedCharges = [...draftCharges]
        updatedCharges.push(finalPayload)
        dispatch(saveDraftCharge({ draftIndex, updatedCharges }))

    }
    const handleChargeRemove = (index: number) => {
        const updatedCharges = [...draftCharges]
        updatedCharges.splice(index, 1)
        dispatch(saveDraftCharge({ draftIndex, updatedCharges }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        dispatch(saveAskToDriveLead({ draftIndex, isAskDriverLead: checked }))
    }
    const handleChargeSubmit = () => {
        navigate(`/user/draft/${draftIndex + 1}`, { state: { scrollToBottom: true } })
    }
    return (
        <>
            <DetailView
                title={`Charges for #${draftNumber}`}
            >
                <Panel title="Volume">
                    <DraftVolumeTable
                        draftVolume={droppOff}
                        onSave={handleChargeSave}
                    />
                </Panel>
                <Panel title="Billable Time">
                    <DraftTimeEntriesAggregated
                        timeEntries={draftDriverHourEntries}
                        onSave={handleChargeSave}
                    />
                </Panel>
                <Panel title="Additional Billable Items">
                    <DraftTicketServicesOverview
                        draftServices={draftServices}
                        onSave={handleChargeSave}
                    />
                </Panel>
                <Panel title="Charges" subtitle="Billing items for this ticket">
                    <DraftChargePanelOverview
                        draftCharges={draftCharges}
                        onSave={handleChargeSave}
                        onRemove={handleChargeRemove}
                    />
                </Panel>
                {
                    userRole === "driver" &&
                    <HStack>
                        <Text fontWeight={600} fontSize="sm"  >Ask Driver Lead to add Charges: </Text>
                        <Switch onChange={handleChange} isChecked={isAskDriverLead} />
                    </HStack>
                }
                <Flex w="full" justifyContent="flex-end">
                    <Button size={["sm", "md"]} colorScheme="blue"
                        onClick={handleChargeSubmit}
                    >
                        Save
                    </Button>
                </Flex>
            </DetailView>
        </>
    )
}
