import { useState } from "react"
import {
    Button,
    ButtonGroup,
    Modal,
    ModalProps,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    useMediaQuery,
} from "@chakra-ui/react"
import "react-calendar/dist/Calendar.css"
import Datetime from 'react-datetime';
import moment from "moment";

type ValuePiece = Date | null
type Value = ValuePiece | [ValuePiece, ValuePiece]
interface DateTimeModalProps extends ModalProps {
    title: string
    onPick(value: Value): void
    onCancel(): void
    initialDate?: Value // Accept initialDate as a prop
}

export function DateTimeModal<MyDTPickerProps, MyDTPickerState>({
    title,
    onClose,
    onCancel,
    onPick,
    initialDate,
    ...modalProps
}: DateTimeModalProps) {
    const [selectedTimeDate, setSelectedTimeDate] = useState<Value>(new Date())
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    const [isSmallScreen] = useMediaQuery("(max-width: 480px)")
    const modalSize = isLargerThanMobile ? "md" : isSmallScreen ? "xs" : "sm"

    const handleCancel = () => {
        onClose()
        onCancel()
    }

    const handleSave = () => {
        onClose()
        onPick(selectedTimeDate)
    }

    const handleChange = (value: any) => {
        setSelectedTimeDate(value?._d)
    }
    const validDate = (current: any) => {
        const date = moment(current); // Ensure current is a moment object
        return date.isSameOrBefore(moment(), 'day'); // Allow today and disable future dates
    };

    return (
        <Modal onClose={handleCancel} size={modalSize} {...modalProps}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w="full" gap={6}>
                        <Datetime className="dateTimeField" onChange={handleChange} open={true} input={false} timeConstraints={{ minutes: { min: 0, max: 59, step: 5 } }} isValidDate={validDate} />
                    </VStack>
                </ModalBody>
                <ButtonGroup w="full" p={3} flex="row" justifyContent="flex-end">
                    <Button onClick={handleSave} colorScheme="blue">
                        Select
                    </Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </ButtonGroup>
            </ModalContent>
        </Modal>
    )
}
