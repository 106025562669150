import { formatDistance, parseISO } from "date-fns"
import { DetailView } from "../../layout/views/DetailView"
import { Stream } from "../../components/Stream"
import { Strip } from "../../components/Strip"
import { StripLine } from "../../components/StripLine"
import { StripLabel } from "../../components/StripLabel"
import { useChangePasswordMutation, useRetrieveProfileQuery } from "../../features/profile/profileApi"
import { ErrorBox } from "../../components/ErrorBox"
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import { KnownIcon } from "../../components/widgets/KnownIcon"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useState } from "react"
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { baseValidationSchema } from '../../app/utils';
type Inputs = {
  current_password: string,
  new_password: string,
  confirm_new_password: string
}

const validationSchema = baseValidationSchema.shape({
  current_password: yup.string().required("please enter this field")
    .min(8, "Password must have at least 8 characters")
    .trim()
});

export function ProfilePage() {
  const toast = useToast()
  const { data, isLoading, isError, error } = useRetrieveProfileQuery()
  const [showError, setShowError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false)
  const [confNewPasswordShow, setConfNewPasswordShow] = useState<boolean>(false)
  const { isOpen, onOpen, onClose: closeModal } = useDisclosure()
  const [changePassword] = useChangePasswordMutation()

  const created =
    data !== undefined
      ? formatDistance(parseISO(data?.created), new Date(), {
        addSuffix: true,
      })
      : null

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema)
  })

  const handlePasswordChange = async (data: Inputs) => {
    try {
      const response = await changePassword(data).unwrap();
      toast({
        title: 'Password Reset Successful.',
        description: "Your password has been reset successfully.",
        status: 'success',
        duration: 5000, // 5 sec duration
        isClosable: true,
        position: 'top'
      });
      console.log('Password change successful:', response);
      onClose()
      reset()
    } catch (err) {
      console.error('Password change failed:', err);
      const error = err as FetchBaseQueryError;
      const errString = Object.entries(error?.data as object).map(([key, value]) => {
        return value.message
      }).join()
      setShowError(true)
      setErrorMessage(errString)
    }
  }
  const onClose = () => {
    reset(); // Clear the form state
    closeModal(); // Close the modal
    setNewPasswordShow(false)
    setConfNewPasswordShow(false)
    setShowError(false)
  };
  return (
    <>
      <DetailView title="Profile" isLoading={isLoading}>
        <ErrorBox show={isError} error={error} />
        <Stream title="User Information">
          <Strip>
            <StripLine>
              <StripLabel strong>Username</StripLabel>
              <StripLabel right>{data?.user.username}</StripLabel>
            </StripLine>
            <StripLine>
              <StripLabel strong>Role</StripLabel>
              <StripLabel right>{data?.role}</StripLabel>
            </StripLine>
            <StripLine>
              <StripLabel strong>Created</StripLabel>
              <StripLabel right>{created}</StripLabel>
            </StripLine>
            <StripLabel >
              <Button leftIcon={<KnownIcon name="settings" size={4} />} size="sm" onClick={() => onOpen()} >
                Reset Password
              </Button>
            </StripLabel>
          </Strip>
        </Stream>
      </DetailView>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Your Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w="full">
              <form id="change_password" onSubmit={handleSubmit(handlePasswordChange)} >
                <ErrorBox show={showError} message={errorMessage} />
                <VStack align="start" width="full" gap={3}>
                  <FormControl isInvalid={!!errors.current_password}>
                    <FormLabel size="sm" fontWeight={600} >Current Password <span style={{ color: 'red' }}>*</span></FormLabel>
                    <Input
                      {...register("current_password")}
                      type='password'
                      placeholder='write it here...'
                    />
                    <FormErrorMessage>{errors.current_password?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.new_password}>
                    <FormLabel size="sm" fontWeight={600} >New Password <span style={{ color: 'red' }}>*</span></FormLabel>
                    <InputGroup>
                      <Input
                        {...register("new_password")}
                        type={newPasswordShow ? 'text' : 'password'}
                        placeholder='write it here...'
                      />
                      <InputRightElement width='4.5rem'>
                        {
                          newPasswordShow ?
                            <Icon as={VscEyeClosed} onClick={() => setNewPasswordShow(!newPasswordShow)} boxSize={6} cursor="pointer" />
                            :
                            <Icon as={VscEye} onClick={() => setNewPasswordShow(!newPasswordShow)} boxSize={6} cursor="pointer" />
                        }
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.confirm_new_password}>
                    <FormLabel size="sm" fontWeight={600}>Confirm New Password <span style={{ color: 'red' }}>*</span></FormLabel>
                    <InputGroup>
                      <Input
                        {...register("confirm_new_password")}
                        type={confNewPasswordShow ? 'text' : 'password'}
                        placeholder='write it here...'
                      />
                      <InputRightElement width='4.5rem'>
                        {
                          confNewPasswordShow ?
                            <Icon as={VscEyeClosed} onClick={() => setConfNewPasswordShow(!confNewPasswordShow)} boxSize={6} cursor="pointer" />
                            :
                            <Icon as={VscEye} onClick={() => setConfNewPasswordShow(!confNewPasswordShow)} boxSize={6} cursor="pointer" />
                        }
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.confirm_new_password?.message}</FormErrorMessage>
                  </FormControl>
                </VStack>
              </form>
            </Box >
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button
              type="submit"
              size={["md"]}
              colorScheme="blue"
              isLoading={isSubmitting}
              form="change_password"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
