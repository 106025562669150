import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { loginUser } from "./sessionSlice"
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
  Heading,
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
} from "@chakra-ui/react"
import { useState } from "react"
import { REQUIRED_FIELDS } from "../draft/RequiredFields"
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useMessageBox } from "../../app/hooks"
import { MessageBox } from "../../components/MessageBox"


type Inputs = {
  username: string
  password: string
}
export function SessionLogin() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // Default form values
  const defaultValues = {
    username: '',
    password: '',
  };
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = useForm<Inputs>({ defaultValues })
  const [passwordShow, setPasswordShow] = useState<boolean>(false)
  const { showAlert, alertStatus, alertMessage, errorDetail, setAlert } = useMessageBox();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const result = await dispatch(loginUser(data) as any)

      let errorString = ''
      if (result?.error) {
        const errors = result?.payload.data
        if (Object.entries(errors).length > 1) {
          const err = Object.entries(errors).map(([key, value]) => {
            const text = REQUIRED_FIELDS.find(
              (option) => option.value === key,
            )?.label
            return text
          })
          errorString = `${err.join(" and ")} are required`
        } else {
          switch (Object.entries(errors)[0][0]) {
            case "password":
              errorString = errors['password'][0];
              break;
            case "username":
              errorString = errors['username'][0];
              break;
          }
        }
        setAlert('error', errorString)
      } else {
        console.info("Logged in user", result?.payload?.profile)
        const role = result?.payload?.profile.role
        if (role === 'customer') {
          console.log("Navigating to tickets page")
          navigate("/user/tickets")
        } else {
          console.log("Navigating to home page")
          navigate("/user/home/")
        }
      }
    } catch (error) {
      console.error("Login failed for user", error)
    }
    finally {
      reset()
    }
  }

  return (
    <VStack width="full" gap={6}>
      <Heading as="h5" size="md">
        Login
      </Heading>
      {/* <ErrorBox show={show} message={errorMessage} /> */}
      <MessageBox
        show={showAlert}
        status={alertStatus}
        message={alertMessage}
        error={errorDetail}
      />
      <Box w="full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack align="start" width="full" gap={3}>
            <FormControl isInvalid={!!errors.username}>
              <FormLabel>Username</FormLabel>
              <Controller
                control={control}
                name="username"
                rules={{ required: 'A username is required' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    onChange={(e) => field.onChange(e.target.value.trim().length > 0 ? e.target.value : '')} // Trim value on change
                  />
                )}
              />
              <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <Controller
                control={control}
                name="password"
                rules={{ required: 'Password is missing' }}
                render={({ field }) => (
                  <InputGroup>
                    <Input
                      {...field}
                      type={passwordShow ? 'text' : 'password'}
                      onChange={(e) => field.onChange(e.target.value.trim())} // Trim value on change
                    />
                    <InputRightElement width='4.5rem'>
                      {
                        passwordShow ?
                          <Icon as={VscEyeClosed} onClick={() => setPasswordShow(!passwordShow)} boxSize={6} cursor="pointer" />
                          :
                          <Icon as={VscEye} onClick={() => setPasswordShow(!passwordShow)} boxSize={6} cursor="pointer" />
                      }
                    </InputRightElement>
                  </InputGroup>

                )}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <Flex width="full" justifyContent={["center", "flex-end"]}>
              <Button
                type="submit"
                size={["md"]}
                colorScheme="blue"
                isLoading={isSubmitting}
                minWidth={40}
              >
                Login
              </Button>
            </Flex>

            <Box w="full" textAlign="center" backgroundColor="blue.50" p={4} rounded="lg">
              <Text >Forgot Password?
                <Link to="/auth/forgot-password" className="fw-bold  ms-3" style={{ color: "#3182ce", margin: "0 10px" }}><u>Reset here</u></Link>
              </Text>
            </Box>
          </VStack>
        </form>
      </Box>
    </VStack>
  )
}
